import React, {Component} from "react";
import Route from "./Routes/routeIndex";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
//import style
import "./assets/css/pe-icon-7.css";
import "./assets/css/materialdesignicons.min.css";
import "./assets/scss/theme.scss";
import {ToastContainer} from "react-toastify";

const App = () => {
    return (
        <>
            <Route/>
            <ToastContainer />
        </>
    );
}

export default App;
