import Layout2 from '../pages/Layout2/Layout2';
import TermsAndConditionsPage from "../pages/TermsAndConditions/TermsAndConditionsPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicy/PrivacyPolicyPage";

const routes = [
    {path: "/", component: <Layout2/>},
    {path: "home", component: <Layout2/>},
    {path: "privacy-policy", component: <PrivacyPolicyPage/>},
    {path: "terms-and-conditions", component: <TermsAndConditionsPage/>},
];

export default routes;
