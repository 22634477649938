import NavBar from "../../component/Navbar/NavBar";
import {NavLink} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {useLayoutEffect} from "react";

const TermsAndConditionsPage = () => {
    const navigate = useNavigate();
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <div className={"container"}>
                <div className="row">
                    <div className="col-sm-12">
                        <h1>Terms and Conditions</h1>
                        <p>By accessing this web site, you are agreeing to be bound by these web site Terms and
                            Conditions
                            of Use,
                            all applicable laws and regulations, and agree that you are responsible for compliance with
                            any
                            applicable
                            local laws. If you do not agree with any of these terms, you are prohibited from using or
                            accessing this
                            site. The materials contained in this web site are protected by applicable copyright and
                            trade
                            mark
                            law.</p>
                        <h2>Use License</h2>
                        <p>Permission is granted to temporarily download one copy of the materials (information or
                            software)
                            on
                            Everridge Ltd web site for personal, non-commercial transitory viewing only. This is the
                            grant of
                            a license,
                            not a transfer of title, and under this license you may not:</p>
                        <ul>
                            <li>modify or copy the materials;</li>
                            <li>use the materials for any commercial purpose, or for any public display (commercial or
                                non-commercial);
                            </li>
                            <li>attempt to decompile or reverse engineer any software contained on Everridge Ltd web
                                site;
                            </li>
                            <li>remove any copyright or other proprietary notations from the materials; or</li>
                            <li>transfer the materials to another person or "mirror" the materials on any other
                                server.
                            </li>
                        </ul>
                        <p>This license shall automatically terminate if you violate any of these restrictions and may
                            be
                            terminated
                            by Everridge Ltd at any time. Upon terminating your viewing of these materials or upon the
                            termination of this
                            license, you must destroy any downloaded materials in your possession whether in electronic
                            or
                            printed
                            format.</p>
                        <h2>Disclaimer</h2>
                        <p>The materials on Everridge Ltd web site are provided "as is". Everridge Ltd makes no
                            warranties,
                            expressed or
                            implied, and hereby disclaims and negates all other warranties, including without
                            limitation,
                            implied
                            warranties or conditions of merchantability, fitness for a particular purpose, or
                            non-infringement of
                            intellectual property or other violation of rights. Further, everridge.co.uk does not
                            warrant or
                            make any
                            representations concerning the accuracy, likely results, or reliability of the use of the
                            materials on its
                            Internet web site or otherwise relating to such materials or on any sites linked to this
                            site.</p>
                        <h2>Limitations</h2>
                        <p>In no event shall Everridge Ltd or its suppliers be liable for any damages (including,
                            without
                            limitation,
                            damages for loss of data or profit, or due to business interruption,) arising out of the use
                            or
                            inability
                            to use the materials on Everridge Ltd Internet site, even if Everridge Ltd or a Everridge
                            Ltd authorised
                            representative has been notified orally or in writing of the possibility of such damage.
                            Because
                            some
                            jurisdictions do not allow limitations on implied warranties, or limitations of liability
                            for
                            consequential or incidental damages, these limitations may not apply to you.</p>
                        <h2>Revisions and Errata</h2>
                        <p>The materials appearing on Everridge Ltd web site could include technical, typographical, or
                            photographic
                            errors. Everridge Ltd does not warrant that any of the materials on its web site are
                            accurate,
                            complete, or
                            current. Everridge Ltd may make changes to the materials contained on its web site at any
                            time
                            without notice.
                            Everridge Ltd does not, however, make any commitment to update the materials.</p>
                        <h2>Links</h2>
                        <p>Everridge Ltd has not reviewed all of the sites linked to its Internet web site and is not
                            responsible for
                            the contents of any such linked site. The inclusion of any link does not imply endorsement
                            by
                            Everridge Ltd of
                            the site. Use of any such linked web site is at the user's own risk.</p>
                        <h2>Site Terms of Use Modifications</h2>
                        <p>Everridge Ltd may revise these terms of use for its web site at any time without notice. By
                            using
                            this web
                            site you are agreeing to be bound by the then current version of these Terms and Conditions
                            of
                            Use.</p>
                        <h2>Governing Law</h2>
                        <p>Any claim relating to Everridge Ltd web site shall be governed by the laws of the United
                            Kingdom
                            without
                            regard to its conflict of law provisions.</p>
                    </div>
                </div>
                <div className={"text-center my-5"}>
                    <button className={"btn btn-light"} onClick={()=>navigate("/")}>{"Back to home page"}</button>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditionsPage;