import {NavLink} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {useLayoutEffect} from "react";

const PrivacyPolicyPage = () => {
    const navigate = useNavigate();
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1>Privacy Policy</h1>
                        <p>This privacy policy sets out how Everridge Ltd uses and protects any information that you
                            give
                            Everridge Ltd when you use this website.</p>
                        <p>Everridge Ltd is committed to ensuring that your privacy is protected. Should we ask you to
                            provide
                            certain information by which you can be identified when using this website, then you can be
                            assured that it will only be used in accordance with this privacy statement.</p>
                        <p>Everridge Ltd may change this policy from time to time by updating this page. You should
                            check this
                            page from time to time to ensure that you are happy with any changes. This policy is
                            effective
                            from 01.05.2023. </p>
                        <h2>What we collect</h2>
                        <p>We may collect the following information:</p>
                        <ul>
                            <li>name and job title</li>
                            <li>contact information including email address</li>
                            <li>demographic information such as postcode, preferences and interests</li>
                            <li>other information relevant to customer surveys and/or offers</li>
                        </ul>
                        <h2>What we do with the information we gather</h2>
                        <p>We require this information to understand your needs and provide you with a better service,
                            and
                            in particular for the following reasons:</p>
                        <ul>
                            <li>Internal record keeping.</li>
                            <li>We may use the information to improve our products and services.</li>
                            <li>We may periodically send promotional emails about new products, special offers or other
                                information which we think you may find interesting using the email address which you
                                have
                                provided.
                            </li>
                            <li>From time to time, we may also use your information to contact you for market research
                                purposes. We may contact you by email, phone, fax or mail. We may use the information to
                                customise the website according to your interests.
                            </li>
                        </ul>
                        <h2>Security</h2>
                        <p>We are committed to ensuring that your information is secure. In order to prevent
                            unauthorised
                            access or disclosure, we have put in place suitable physical, electronic and managerial
                            procedures to safeguard and secure the information we collect online.</p>
                        <h2>How we use cookies</h2>
                        <p>A cookie is a small file which asks permission to be placed on your computer's hard drive.
                            Once
                            you agree, the file is added and the cookie helps analyse web traffic or lets you know when
                            you
                            visit a particular site. Cookies allow web applications to respond to you as an individual.
                            The
                            web application can tailor its operations to your needs, likes and dislikes by gathering and
                            remembering information about your preferences.</p>
                        <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data
                            about web page traffic and improve our website in order to tailor it to customer needs. We
                            only
                            use this information for statistical analysis purposes and then the data is removed from the
                            system.</p>
                        <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which
                            pages
                            you find useful and which you do not. A cookie in no way gives us access to your computer or
                            any
                            information about you, other than the data you choose to share with us.</p>
                        <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies,
                            but
                            you can usually modify your browser setting to decline cookies if you prefer. This may
                            prevent
                            you from taking full advantage of the website.</p>
                        <h3>DoubleClick Cookie</h3>
                        <p>We use Google Analytics remarketing codes to log when users view specific pages or take
                            specific
                            actions on a website. This allows us to provide targeted advertising in the future. If you
                            do
                            not wish to receive this type of advertising from us in the future you can opt out using the
                            DoubleClick opt-out page or the Network Advertising Initiative opt-out page.</p>
                        <h2>Controlling your personal information</h2>
                        <p>You may choose to restrict the collection or use of your personal information in the
                            following
                            ways:</p>
                        <ul>
                            <li>whenever you are asked to fill in a form on the website, look for the box that you can
                                click
                                to indicate that you do not want the information to be used by anybody for direct
                                marketing
                                purposes
                            </li>
                            <li>if you have previously agreed to us using your personal information for direct marketing
                                purposes, you may change your mind at any time by writing to or emailing us at <a
                                    href="mailto:info@everridge.co.uk">info@everridge.co.uk</a></li>
                        </ul>
                        <p>We will not sell, distribute or lease your personal information to third parties unless we
                            have
                            your permission or are required by law to do so. We may use your personal information to
                            send
                            you promotional information about third parties which we think you may find interesting if
                            you
                            tell us that you wish this to happen.</p>
                        <p>You may request details of personal information which we hold about you under the Data
                            Protection
                            Act 1998. A small fee will be payable. If you would like a copy of the information held on
                            you
                            please write to <a
                                href="mailto:info@everridge.co.uk">info@everridge.co.uk</a></p>
                        <p>If you believe that any information we are holding on you is incorrect or incomplete, please
                            write to or email us as soon as possible, at the above address. We will promptly correct any
                            information found to be incorrect. </p>
                    </div>
                </div>
                <div className={"text-center my-5"}>
                    <button className={"btn btn-light"} onClick={() => navigate("/")}>{"Back to home page"}</button>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicyPage;